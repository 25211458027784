import '../css/app.scss';


import 'jquery';
import $ from 'jquery';

import './libs/bootstrap.4.5.min.js';

import './libs/jquery.validation'
import './libs/bootstrap.bundle.min'
import './libs/owl.carousel.min.js'
import './libs/ion.rangeSlider.min.js'
import './libs/owlcarousel2-progressbar'
import './libs/splide'

import './bootstrap';
import './libs/jquery.appear';
import './libs/scrolla.jquery.min.js';
import './libs/jquery.wavify';
import './libs/jquery.fancybox';
import './libs/owl.carousel.min.js';
import './custom';
// import 'bootstrap-datepicker';
// import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.css'

import 'selectize';
import './libs/select2.min'

// import 'bootstrap-datetimepicker';
// import 'jquery-datetimepicker';

// import 'owl.carousel/dist/assets/owl.carousel.min.css';
// import 'owl.carousel/dist/assets/owl.theme.default.min.css';
// import 'owl.carousel';
import Vue from 'vue';
import './infrastructure/Flash';
import './infrastructure/Flashes';
// import './infrastructure/Tab';
// import './infrastructure/Tabs';
// import './infrastructure/Modal';
// import './infrastructure/Carousel';
// import './infrastructure/DeleteLink';
// import './infrastructure/DeleteModuleItem';
// import './infrastructure/PostLink';
// import './infrastructure/InlineValueEdit';
// import './infrastructure/InlineBooleanEdit';
// import './infrastructure/FileUploadButton';
// import './infrastructure/FilesList';
// import './infrastructure/Slider';
// import './components/EntityTranslation';
// import './components/EntityTranslationsForm';
// import './components/CreditCalculator';
// import './components/CreditOffer';
// import './components/CreditComparision';
// import './components/PollAnswer';
// import './components/PollQuestion';
// import './components/Poll';
// import './components/Gmaps';
// import './components/SliderModule';
// import './components/PollParticipation';
// import './components/ImageGallery';
// import './components/CookieDeclaration';
// import './components/ZopimChat';
// import './components/TranslationsList';
// import './components/TranslationItem';

import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"

window.EventManager = new class {
    constructor() {
        this.vue = new Vue();
    }

    fire(event, data = null) {
        this.vue.$emit(event, data);
    }

    listen(event, callback) {
        this.vue.$on(event, callback);
    }
};

window.flash = function (message, level = 'success') {
    window.EventManager.fire('flash', {message, level});
};

const application = Application.start();
const context = require.context("./controllers/global", true, /\.js$/);
application.load(definitionsFromContext(context));

Vue.options.delimiters = ['[[', ']]'];
Vue.config.ignoredElements = ['x-trans'];

const app = new Vue({
    el: '#app',
    data: {
        showModal: false,
        dynamicComponents: []
    },
    mounted() {
        EventManager.listen('add-component', this.addComponent);
        EventManager.listen('remove-component', this.removeComponent);
    },
    methods: {
        addComponent(data) {
            this.dynamicComponents.push(data);
        },

        removeComponent(componentId) {
            let index = this.dynamicComponents.findIndex(item => item.id === componentId);

            this.dynamicComponents.splice(index, 1);
        },
    }
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});

let elements = document.querySelectorAll('input, select, textarea');

elements.forEach(element => {
    element.addEventListener('invalid', function () {
        element.scrollIntoView(false);
    });
});

$(function () {
    $("form").submit(function (event) {
        $(this).find('button[type="submit"]').prop("disabled", true);
    });
});

$(function () {

    $('.nav-btn').click(function () {
        $('body, nav, .nav-items .nav-btn, .nav-items').toggleClass('open');
    });

    $('.owl-testimonials').owlCarousel({
        center: true,
        items: 2,
        loop: true,
        margin: 100,
        responsive: {
            0: {
                items: 1,
                margin: 10,
            },
            768: {
                items: 1,
                margin: 10,
            },
            1000: {
                items: 2,
                margin: 100,
            }
        }
    });

    $('.owl-partners').owlCarousel({
        center: true,
        items: 2,
        loop: true,
        margin: 100,
        responsive: {
            600: {
                items: 5
            }
        }
    });

    // $(".owl-carousel").owlCarousel({
    //     loop:true,
    //     center:true,
    //     margin:10,
    //     autoplay:true,
    //     autoplayTimeout:5000,
    //     dotsEach:true,
    //     animateOut:true,
    //     animateIn:true,
    //
    // });
});

$(function () {
    $("#customConsents").on("click", function () {
        let display = $("#marketingConsentsContainer").css("display");

        if (display === "none") {
            $("#marketingConsentsContainer").show();
        } else {
            $("#marketingConsentsContainer").hide();
        }
    });

    $("#application_middle_form_marketingConsent").on("change", function (e) {
        $("#application_middle_form_marketingConsentCourier").prop("checked", this.checked);
        $("#application_middle_form_marketingConsentEmail").prop("checked", this.checked);
        $("#application_middle_form_marketingConsentPhone").prop("checked", this.checked);
        $("#application_middle_form_marketingConsentSms").prop("checked", this.checked);
    })


    function checkGaLoaded(gaLoaded) {
        if (typeof ga !== 'undefined') {
            if (ga.loaded) {
                ga(function () {
                    var tracker = ga.getAll()[0];
                    var clientId = tracker.get('clientId');
                    if (clientId) {
                        var input = document.querySelector(".googleAnalyticsClientIdInput");
                        if (input) {
                            input.value = clientId;
                            clearInterval(gaLoaded);
                        }
                    }
                });
            }
        }
    }

    var gaLoaded = window.setInterval(function () {
        checkGaLoaded(gaLoaded);
    }, 1000);
});

$(function () {
    $(".youtube_link").on("click", function () {
        console.log("HERE ", this.dataset)
        if (this.dataset.url) {
            $("#youtube_video").attr("src", "https://www.youtube.com/embed/" + this.dataset.url + "?enablejsapi=1&version=3&playerapiid=ytplayer&rel=0");
        }
    });

    $('label[for="career_application_application"]').hide();


    var hash = window.location.hash;
    if (hash) {
        setTimeout(function () {
            goToByScroll(hash);
        }, 500);
    }
    // $(".form_datetime").datetimepicker({format: 'yyyy-mm-dd hh:ii'});

    // $('.form_datetime').datetimepicker();

    var section = document.getElementById('scrollTo');
    if (section) {
        // goToByScroll(section.value);
        // window.location.href = 'http://violeta.bg.wip/partniori#' + section.value;
        window.location.href = window.location.pathname + '#' + section.value;
    }

    if (window.location.hash === "#ipoteka" && window.location.pathname.includes("thankyou")) {
        history.replaceState
            ? history.replaceState(null, null, window.location.href.split("#")[0])
            : window.location.hash = "";
    }
});

function goToByScroll(id) {

    if($(id).offset()) {
        $('html,body').animate({scrollTop: $(id).offset().top}, 'slow');
    }
}


function flashMessage(msg, type) {

    if (type === "success") {
        $("#msgContainer").attr("class", "alert alert-success");
        document.getElementById("flashMessage").innerHTML = msg;
        setTimeout(function () {
            $('#myFlashesModal').modal('show')
        }, 500)

        setTimeout(function () {
            $('#myFlashesModal').modal('hide')
        }, 5000)
    } else if (type === "danger") {
        $("#msgContainer").attr("class", "alert alert-danger");
        document.getElementById("flashMessage").innerHTML = msg;
        setTimeout(function () {
            $('#myFlashesModal').modal('show')
        }, 500)

        setTimeout(function () {
            $('#myFlashesModal').modal('hide')
        }, 5000)
    }


}

window.flashMessage = flashMessage;
